<template>
    <div>
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Requisições">
                <LogsRequisicoes />
            </CTab>
        </CTabs>
    </div>
</template>

<script>
  import LogsRequisicoes from "./requisicoes/LogsRequisicoes";

  export default {
    name: "Logs",
    components: {
      LogsRequisicoes
    },
  }
</script>